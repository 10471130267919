export default {
  sell: 'Bán định danh',
  posted: 'Đã đăng',
  agreement: 'Thương lượng',
  create: 'Tạo mới',
  form: {
    titleSetting: 'Cài đặt shop hoàn định danh',
    title: 'Bán hoàn định danh',
    price: 'Giá bán',
    phone: 'Số điện thoại',
    note: 'Ghi chú',
    notePrivate: 'Ghi chú riêng',
    ticketNumber: 'Số vé',
    agencyCode: 'Đại lý xuất',
    type: 'Loại',
    expiredDate: 'Hạn đăng bán',
    reason: 'Lý do báo cáo',
    source: 'Nguồn',
    status: 'Trạng thái',
    ph: {
      price: 'Nhập số tiền',
      phone: 'Nhập số điện thoại',
      note: 'Nhập ghi chú',
      notePrivate: 'Nhập ghi chú riêng',
      ticketNumber: 'Nhập số vé',
      agencyCode: 'Nhập đại lý xuất',
      type: 'Nhập loại',
      reason: 'Nhập lý do',
      source: 'Chọn nguồn',
    },
  },
  columns: {
    stt: 'STT',
    no: 'STT',
    booker: 'Đại lý',
    type: 'Loại',
    paxName: 'Tên hành khách',
    paxList: 'Danh sách hành khách',
    paxType: 'Loại hành khách',
    flightDates: 'Ngày bay',
    source: 'Nguồn',
    priceTicket: 'Giá vé net',
    price: 'Giá bán',
    phone: 'Số điện thoại',
    note: 'Ghi chú',
    actions: 'Thao tác',
    notePrivate: 'Ghi chú riêng',
    isActive: 'Đăng bán',
    expiredDate: 'Hạn đăng',
    ticketNumber: 'Số vé',
    pnr: 'PNR',
    publishDate: 'Ngày đăng',
    airlineCode: 'Hãng',
    modifiedBy: 'Người thực hiện',
    updatedAt: 'Thời gian sửa',
    newValue: 'Giá trị mới',
    oldValue: 'Giá trị cũ',
    status: 'Trạng thái',
  },
  createSuccess: 'Đăng bán định danh thành công',
  deleteSuccess: 'Xóa vé định danh thành công',
  updateSuccess: 'Cập nhật vé định danh thành công',
  updateStatusSuccess: 'Cập nhật trạng thái vé định danh thành công',
  createReportSuccess: 'Báo cáo thành công',
  redirect: 'Bạn có muốn đến chợ định danh không?',
  btn: {
    call: 'Liên hệ',
    edit: 'Sửa',
    add: 'Thêm',
    delete: 'Xóa',
    report: 'Báo cáo',
    deactive: 'Tạm khóa',
    active: 'Hoạt động',
    pushlishNow: 'Đăng luôn',
    reportDetail: 'Chi tiết báo cáo',
    logsDetail: 'Thao tác',
    ban: 'Cấm đăng bán',
    unban: 'Bỏ cấm',
    editNote: 'Sửa ghi chú',
  },
  type: {
    IDENTITY: 'Định danh',
    AGENT: 'Bảo lưu',
  },
  status: {
    UNPUBLIC: 'Chưa đăng',
    PUBLIC: 'Đã đăng',
    SOLD: 'Đã bán',
  },
  note: {
    title: 'Ghi chú',
    note1: 'Mọi ý kiến đóng góp vui lòng liên hệ SALES.',
    note2:
      'Sau khi giao dịch thành công, Vui lòng chọn thao tác "đã bán" để hạ bài đăng.',
    note3:
      'Tất cả trường hợp đăng bán bị phản ánh, Kỹ thuật sẽ khóa tính năng shop của người đăng.',
    note4: 'BẢO LƯU ĐỊNH DANH KHÔNG ĐƯỢC ÁP CHO CÁC CHẶNG BAY TQ',
    paxName: 'Nhập đầy đủ họ và tên, không nhập giới tính',
    price: 'Nếu không nhập giá bán hiển thị sẽ là "thương lượng".',
    agencyCode: 'Xuất bên đại lý cấp 1 nào ? không bắt buộc.',
    pnrs: 'Lưu ý: Tiền hoàn bảo lưu thực tế có thể khác nếu Người lớn có "em bé đi kèm"',
  },
  report: {
    warning1: '(*) Tuyệt đối không dùng thử (không thử nghiệm)',
    warning2: '(**) Sẽ có 2 luật áp dụng cho báo cáo vi phạm (phản ánh) này:',
    report1: 'Bạn phản ánh sai sự thật, bạn bị khóa tính năng định danh.',
    report2: 'Người bị phản ánh đúng sẽ bị khóa tính năng định danh.',
    total: 'Tổng',
    totalID: 'Bảo lưu định danh',
    totalAG: 'Bảo lưu AG',
    details: 'Chi tiết báo cáo',
  },
  expired: 'Đã quá hạn',
  notFoundId: 'Vé đã bị xóa hoặc không tồn tại',
  banTo: 'Cấm đến',
  reported: 'Có báo cáo',
  unReported: 'Không có báo cáo',
}
