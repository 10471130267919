export default {
  firstName: 'First Name',
  lastName: 'Last Name',
  birthday: 'Birthday',
  email: 'Email',
  phone: 'Phone Number',
  gender: 'Gender',
  companyName: 'Company Name',
  addressCompany: 'Address of company',
  idNumber: 'ID Number',
  issueDate: 'Issue Date',
  expDate: 'Expire Date',
  placeOfIssue: 'Place of issue',
  telegramId: 'Telegram ID',
  enter: 'Enter',
  edit: 'Edit',
  save: 'Save',
  back: 'Back',
  employeeConfig: {
    title: 'Config',
    arrivalCode: 'Arrival code',
    departureCode: 'Departure code',
    isPriceBargainFinder: 'Feature calculate price bargain finder (VN)',
    showAddonForSms: 'Show addon service for SMS',
    showArrivalTimeForSms: 'Show arrival time for SMS',
    showContactSuggestions: 'Show passenger suggestions for booking',
    isShowAircraft: 'Show aircraft',
    isShowBookingClass: 'Show booking class',
    isShowFee: 'Show agency fee',
    isShowFeeService: 'Show service fee',
    isShowPromotion: 'Show promotion',
    showPriceOptions: 'Show price options',
    sortType: 'Sort type',
    errorUpdateConfig: 'Error update config',
    errorUpdateConfigDuplicateCode: 'Departure and arrival must be different',
    successUpdateConfig: 'Update config successfully',
    titleAirport: 'Default Airport',
    titleResultSearch: 'Flight Result',
    titleFeature: 'Feature',
    isInputFullNamePax: 'Enter the full name of the passenger with only 1 input',
  },
  paymentPassword: {
    title: 'Payment password',
    password: 'Password',
    updateError: 'Error update payment password',
    updateSuccess: 'Update payment password successful',
    reset: 'Reset payment password',
    placeholder: 'Enter payment password',
    rePlaceholder: 'Enter again payment password',
    errorInput: 'Wrong input password, please try again',
    differentRePassword: 'Password and re-password must be the same',
    submitInput: 'Submit',
    resetConfirm: 'Are you sure you want to reset payment password?',
    changePassword: 'Change payment password',
    oldPassword: 'Old payment password',
    newPassword: 'New payment password',
    reNewPasswordPlaceholder: 'Enter again new payment password',
    confirmTurnOff: 'Confirm turn off payment password',
  },
  mfa: {
    title: 'Multi-Factor Authentication (MFA)',

    create: {
      title: 'Create MFA',
      otpVerify: 'OTP',
      nameVerify: 'MFA name',
      confirmMfa: 'Verify',
      type: 'Method',
      instruction: {
        title: 'Setup instructions',
        step1Title: '1. Download the authenticator app',
        step1Content: 'If you don\'t have an authenticator app installed, we recommend downloading Google Authenticator.',
        step2Title: '2. Scan this QR code or copy the key',
        step2Content: 'Scan this QR code in the authenticator app or copy the key and paste it into the authenticator app.',
        step3Title: '3. Copy and enter the 6-digit code',
        step3Content: 'After you scan the QR code or enter the key, the authenticator app generates a 6-digit code. Copy it then come back here to enter the code.',
      },
      createMfaError: 'Error create MFA',
      createMfaSuccess: 'Created MFA successfully',
      verifyMfaError: 'Error verify MFA',
    },

    columns: {
      no: 'No.',
      name: 'Name',
      type: 'Method',
      createdAt: 'Created at',
      action: 'Action',
    },
    confirmDelete: 'Are you sure you want to delete this MFA?',
    noData: 'You have not created MFA yet. Please create MFA.',
    enableMfaLogin: 'Enable Multi-Factor Authentication when logging in',
    disableMfaLoginSuccess: 'Disabled Multi-Factor Authentication when logging in',
    enableMfaLoginSuccess: 'Enabled Multi-Factor Authentication when logging in',
    deleteMfaSuccess: 'Delete Multi-Factor Authentication successfully',
    inputOtpMfa: 'Please enter Multi-Factor Authentication OTP',
    disableMfaLoginFirst: 'Please disable Multi-Factor Authentication when logging in before removing MFA',
    InvalidOtpMfa: 'Wrong OTP. Please re-enter OTP!',
    fetchMfaError: 'Error loading MFA data',
    linkToMfa: 'Click here to set up Multi-Factor Authentication (MFA).',
  },
}
