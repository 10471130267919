export function getOsEnv(key, type = null) {
  if (typeof process.env[key] === 'undefined') {
    // throw new Error(`Environment variable ${key} is not set.`)
    console.error(`Environment variable ${key} is not set.`)
    return ''
  }
  const envValue = process.env[key] === 'true' ? true : process.env[key]
  const isTurnOnAllFunctionEnv = localStorage.getItem('isTurnOnAllFunctionEnv') === 'true'

  if (key.startsWith('VUE_APP_FUNC_') && isTurnOnAllFunctionEnv && !type && !['VUE_APP_FUNC_HIDE_TOUR', 'VUE_APP_FUNC_ENABLE_SOURCE_AK_JSON'].includes(key)) {
    return !envValue // isTurnOnAllFunctionEnv
  }
  if (type === 'array') {
    return (envValue.includes(',') ? envValue.split(',') : [envValue]).filter(Boolean)
  }

  return envValue
}

export default {
  getOsEnv,
}
