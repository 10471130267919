export default {
  filters: 'Bộ lọc',
  type: 'Loại',
  createdBy: 'Tạo bởi',
  search: 'Tìm kiếm',
  clear: 'Xóa',
  gender: 'Giới tính',
  employeeCode: 'Mã nhân viên',
  status: 'Trạng thái',
  btnCreate: 'Thêm mới',
  employees: 'Nhân viên',
  empCode: 'Mã nhân viên',
  role: 'Vai trò',
  description: 'Mô tả',
  action: 'Hành động',
  attach: 'Thêm',
  attachRole: 'Thêm vai trò',
  actions: 'Thao tác',

  placeholderSearch: 'Tên, email, tên đăng nhập...',
  emptyText: 'Không tìm thấy kết quả',

  tabInfo: 'Thông tin tài khoản',
  confirmation: 'Xác nhận',

  userName: 'Tên đăng nhập',
  email: 'Email',
  phoneNumber: 'Số điện thoại',
  firstName: 'Tên đệm & Tên',
  lastName: 'Họ',
  employeeType: 'Loại tài khoản',
  agencies: 'Đại lý',
  bookerId: 'ID Booker',
  bookerType: 'Nghiệp vụ Booker',
  telegramId: 'Telegram ID',
  scheduleCodeSE: 'Mã lịch trực sales',
  scheduleCodeBE: 'Mã lịch trực booker',
  placeholderScheduleCode: 'Điền vào mã lịch trực',
  placeholderUsername: 'Điền vào tên đăng nhập',
  placeholderEmail: 'Điền vào email',
  placeholderPhoneNumber: 'Điền vào số điện thoại',
  placeholderFirstName: 'Điền vào Tên đệm & Tên',
  placeholderLastName: 'Điền vào họ',
  placeholderBookerId: 'Điền vào ID Booker',
  placeholderTelegramId: 'Điền vào Telegram ID',
  placeholderSelectType: 'Chọn loại tài khoản',
  placeholderSelectGender: 'Chọn giới tính',
  placeholder: {
    employeeCode: 'Nhập đúng mã nhân viên để tìm kiếm',
  },
  errFetchEmpData: 'Error fetching new employee data',
  gotoEmp_1: 'Go to',
  gotoEmp_2: 'Employee List',

  information: 'Thông tin',
  empInfo: 'Thông tin nhân viên',
  customers: 'Khách hàng',
  historyBooking: 'Lịch sử đặt vé',
  errFetch: 'Lỗi tìm nạp dữ liệu nhân viên',
  errFindId_1: 'Không có nhân viên tìm thấy với nhân viên này ID.Kiểm tra',
  errFindId_2: 'Danh sách nhân viên',
  errFindId_3: 'cho các nhân viên khác.',
  noInfo: 'Không có thông tin',

  checkbox: '',
  created: 'Thời gian tạo',
  updated: 'Thời gian sửa đổi',

  STT: 'STT',
  Employee_First_Name: 'Tên nhân viên',
  Employee_Last_Name: 'Họ nhân viên',
  Employee_Booker_Type: 'Loại Booker',
  Employee_Type: 'Loại nhân viên',
  Gender: 'Giới tính',
  Username: 'Tên tài khoản',
  Email_Address: 'Địa chỉ Email',
  Phone_Number: 'Số điện thoại',

  moreDropdown: {
    title: 'Lựa chọn',
    add: 'Thêm mới nhân viên',
    import: 'Nhập từ Excel',
    export: 'Xuất ra Excel',
  },
  save: 'Lưu',
  managedAgenciesInfo: 'Thông tin các đại lý phụ trách',
  managedAgenciesTable: {
    agencyCode: 'Mã đại lý',
    agencyName: 'Tên đại lý',
    emailAddress: 'Email',
    ownerPhoneNumber: 'Số điện thoại',
    actions: 'Thao tác',
  },
}
