export default {
  Customer: 'Customer',
  textShowCustomer: 'customers',
  textBtnCreate: 'Customer',
  placeholderSearch: 'Name, email, phone number, customer code ...',
  checkbox: '',
  code: 'Customer Code',
  placeholderCode: 'Please enter Customer code',
  identifyNumber: 'Identify Number',
  noIdentifyNumber: 'Not have identify number',
  Dateofissue: 'Date of issue',
  Placeofissue: 'Place of issue',
  addCustomer: 'Add Representative Customer',
  customer: 'Customer',
  editCustomer: 'Passenger edit successful',
  note: 'Describe',
  expirationDate: 'Expiration date',
  membershipCard: 'Membership Card',
  tabInfo: 'Customer Information',
  created: 'Created',
  updated: 'Updated',
  view: 'Showing',
  noEmail: 'No email',
  createdAt: 'Date created',
  addCustomerDetails: 'Add passengers members',
  messageInvaldBir: 'Invalid age category for the selected gender',
  addCustomerTitle: 'Add representative customers',
  typePassPeople: {
    ADULT: 'Adult',
    CHILD: 'Child',
    INFANT: 'Infant',
  },
  genderOptionsGenders: {
    MR: 'Mr',
    MRS: 'Mrs',
    MS: 'Aunt',
    MSTR: 'Baby boy',
    MISS: 'Baby girl',
  },
  detailsCustomer: 'Detail Customer',
  updateCustomer: 'Update Customer',
  socialAccounts: 'Social Accounts',
  STT: 'STT',
  Customer_First_Name: 'Customer First Name',
  Customer_Last_Name: 'Customer Last Name',
  Gender: 'Gender',
  Phone_Number: 'Phone Number',
  Email_Address: 'Email Address',
  Birthday: 'Birthday',
  Description: 'Description',
  Street: 'Street',
  emailAddress: 'Email',
  receivables: 'Revenue',
  profit: 'Profit',
  balanceStartDate: 'Profit over time from day',
  message: 'Invalid age category for the selected gender.',
  invalid_required: 'Date of birth is required for children.',
  successCustomer: 'Customer updated successfully',
  customerList: 'Customer List',
  errFetchData_1: 'Error fetching customer data',
  errFetchData_2: "No customer found with this customer's id. Check",
  errFetchData_3: 'for other customer.',
  addNewMemberCard: 'Add new membership card',
  deleteMemberCard: 'Delete memberShip card',
  noMemberShipCard: 'There are no membership cards',

  fullName: 'Full Name',
  dateOfBirth: 'Date Of Birth',
  typeOfPassenger: 'Type of Passenger',
  Action: 'Action',
  nationality: 'Nationality',
  addContact: 'Add Contact',
  editContact: 'Edit Contact',

  errFetchContact: 'Error fetching contacts data',
  noContact_1: 'No contacts found with this contacts id. Check',
  noContact_2: 'contacts List',
  noContact_3: 'for other contacts.',

  moreDropdown: {
    title: 'More',
    add: 'Add Customer',
    import: 'Import Customer',
    export: 'Export Customer',
    delete: 'Delete Customer',
  },
  memberCards: {
    cardNumber: 'Membership card number',
    airline: 'Airline',
    supplierCode: 'SupplierCode',
  },
  genderOptionsGender: {
    Male: 'Male',
    Female: 'Female',
    Neutral: 'Neutral',
    Mistress: 'Baby boy',
    Miss: 'Baby girl',
  },
  addContactCustomer: 'Add KH Member',
  detailsContact: 'Passenger details',
  updateContact: 'Passenger edit',
  Account: 'Account',
  Contact: 'Contact',
  historyBooking: 'History Booking',
  noInfo: 'No information',
  nickName: 'Nick name',
  Back: 'Back',
  accountInfoText: 'Account information',
  lastName: 'Last name',
  placeholderLastName: 'Enter last name',
  firstName: 'First name',
  placeholderFirstName: 'Enter first name',
  email: 'Email',
  passport: 'Passport',
  phoneNumber: 'Phone number',
  placeholderPhoneNumber: 'Enter phone number',
  dob: 'Date of birth',
  quantity: 'Quantity',
  placeholderDoB: 'Choose date of birth',
  gender: 'Title',
  placeholderSelectGender: 'Select gender',
  backupPhoneNumber: 'Backup phone number',
  description: 'Description',
  placeholderDescription: 'Enter description',
  address: 'Address',
  country: 'Country',
  placeholderCountry: 'Select country',
  loadingCountryText: 'Loading country...',
  noResultFund: 'No results found for',
  noOptions: 'Sorry, no matching options.',
  city: 'Province/City',
  placeholderCity: 'Select city',
  loadingCityText: 'Loading city...',
  district: 'District',
  placeholderDistrict: 'Select District',
  loadingDistrictText: 'Loading District...',
  ward: 'Sub District/Ward',
  placeholderWard: 'Select ward',
  loadingWardText: 'Loading ward...',
  street: 'Address street',
  placeholderStreet: 'Enter street',
  needSelect_1: 'You need select',
  needSelect_2: 'first.',
  back: 'Back',
  clear: 'Clear',
  next: 'Next',
  save: 'Save',
  delete: 'Delete',
  details: 'Detail',
  noInformation: 'No Information',
  noPhoneNumber: 'No phone number',
  placeholderEmail: 'Enter email',
  contact: 'Contact',
  addNew: 'Add New',
  passengerType: 'Type of Passenger',
  contactInfoText: 'Contact Information',
  information: 'Information',
  noRecordFund: 'No matching records found',
  placeholderSearchContact: 'Name, email...',
  edit: 'Edit',
  cancel: 'Cancel',
  memberShip: 'Membership card',
}
