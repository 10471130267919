export default {
  messBoard: 'Message Board',
  note: 'Note',
  notification: 'Notification',
  promotion: 'Promotion',
  sort: {
    original: 'original',
    asc: 'asc',
    desc: 'desc',
  },
  priorityPin: {
    HIGH: 'HIGH',
    MEDIUM: 'MEDIUM',
    LOW: 'LOW',
    ALL: 'ALL',
  },
  from: 'FROM',
  name: {
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low',
    ALL: 'All',
  },
  viewMore: 'View More',
  collapse: 'Collapse',
  detail: 'Detail',
  readAllMessages: 'Mark all as read',
  noInformation: 'No notes',
  allNotiShown: 'All notifications shown',
  unRead: 'Unread notifications',
  searchNameNoti: 'Search for notifications',
  searchNamePromotion: 'Search for promotions',
  viewBooking: 'View booking',
}
