export default {
  form: {
    fullName: 'Full name',
    shortName: 'Short name',
    paymentCode: 'Payment Code',
    paymentCodePrefix: 'Payment Code Prefix',
    paymentCodeSuffixFrom: 'Payment Code Suffix From',
    paymentCodeSuffixTo: 'Payment Code Suffix To',
    paymentCodeSuffixCharacterType: 'Payment Code Suffix Character Type',
    transactionAmount: 'Transaction Amount',
    paymentTxsFee: 'Transaction Fee',
    ph: {
      fullName: 'Enter full name',
      shortName: 'Enter short name',
      paymentCode: 'Enter Payment Code',
      paymentCodePrefix: 'Enter Payment Code Prefix',
      paymentCodeSuffixFrom: 'Enter Payment Code Suffix From',
      paymentCodeSuffixTo: 'Enter Payment Code Suffix To',
      paymentCodeSuffixCharacterType:
        'Enter Payment Code Suffix Character Type',
      transactionAmount: 'Enter Transaction Amount',
      paymentTxsFee: 'Enter transaction Fee',
    },
  },
  columns: {
    stt: 'STT',
    companyId: 'Id Company',
    agency: 'Agency',
    bankId: 'Id Bank',
    accountHolderName: 'Account Holder Name',
    bankAccountName: 'Account Holder Name',
    accountNumber: 'Account Number',
    accumulated: 'Accumulated',
    label: 'Label',
    bankApiConnected: 'Bank Api Connected',
    lastTransaction: 'Last Transaction',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    fullName: 'Full name',
    shortName: 'Short name',
    status: 'Status',
    transactionId: 'Transaction Id',
    transactionDate: 'Transaction Date',
    bankAccountId: 'Bank Account Id',
    paymentBankAccountId: 'Payment Bank Account Id',
    vaId: 'VA Id',
    va: 'VA',
    referenceNumber: 'Reference Number',
    transactionContent: 'Transaction Content',
    paymentCode: 'Payment Code',
    transferType: 'Transfer Type',
    amount: 'Amount',
    actions: 'Actions',
    details: 'Details',
    date: 'Date',
    transaction: 'Total transaction',
    transactionIn: 'Transaction In',
    transactionOut: 'Transaction Out',
    paymentTxsFee: 'Transaction Fee',
  },
  btn: {
    detailCompany: 'Edit Company',
    detailConfig: 'Detail Config',
    detailCouter: 'Detail Counter',
  },
  status: {
    Pending: 'Pending',
    Active: 'Active',
    Suspended: 'Suspended',
    Terminated: 'Terminated',
    Cancelled: 'Cancelled',
    Fraud: 'Fraud',
    debit: 'Debit',
    credit: 'Credit',
  },
  suffix: {
    NumberAndLetter: 'Number And Letter',
    NumberOnly: 'Number Only',
  },
  search: {
    key: 'Key word',
    transaction:
      'Search keyword by transaction content, FT reference number, payment code, VA number',
    company: 'Search keyword by full name, abbreviation',
    bankAccount:
      'Search keyword by cardholder name, account number, and nickname',
  },
  connected: 'Connected',
  disconnected: 'Disconnected',
  count: 'Count',
  countIn: 'Count In',
  countOut: 'Count Out',
  unlimited: 'Unlimited',
  configCompanySuccess: 'Config company success',
}
