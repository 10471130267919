export default {
  form: {
    title: 'Thông tin ngân hàng',
    name: 'Tên',
    code: 'Mã',
    shortName: 'Tên viết tắt',
    logo: 'Logo',
    bin: 'BIN',
    swiftCode: 'Mã Swift',
    support: 'Hỗ trợ',
    gateway: 'Cổng thanh toán',
    isAuto: 'Tự động',
    paymentTxsFee: 'Phí xác nhận giao dịch',
    createdAt: 'Ngày tạo',
    updatedAt: 'Ngày cập nhật',
    ph: {
      id: 'Nhập mã định danh',
      name: 'Nhập tên',
      code: 'Nhập mã',
      shortName: 'Nhập tên viết tắt',
      logo: 'Nhập logo',
      bin: 'Nhập BIN',
      swiftCode: 'Nhập mã Swift',
      support: 'Nhập hỗ trợ',
      gateway: 'Nhập cổng thanh toán',
      isAuto: 'Nhập tự động',
      paymentTxsFee: 'Nhập phí xác nhận giao dịch',
      createdAt: 'Nhập ngày tạo',
      updatedAt: 'Nhập ngày cập nhật',
    },
  },
  columns: {
    stt: 'STT',
    name: 'Tên',
    code: 'Mã',
    shortName: 'Tên viết tắt',
    logo: 'Logo',
    bin: 'BIN',
    swiftCode: 'Mã Swift',
    support: 'Hỗ trợ',
    gateway: 'Cổng thanh toán',
    isAuto: 'Tự động',
    paymentTxsFee: 'Phí xác nhận giao dịch',
    createdAt: 'Ngày tạo',
    updatedAt: 'Ngày cập nhật',
    actions: 'Thao tác',
  },
  btn: {
    edit: 'Sửa',
  },
  updateSuccess: 'Cập nhật thành công',
}
