export default {
  title: 'Cài đặt phí xuất vé',
  titleList: 'Danh sách cài đặt phí xuất vé',
  feeType: 'Loại phí',
  airline: 'Hãng hàng không',
  distributor: 'Hệ thống đặt vé',
  amount: 'Phí',
  FLIGHT_SEGMENT: 'Theo chặng có điểm dừng / khách',
  FLIGHT_ITINERARY: 'Theo chặng / khách',
  INLAND: 'Nội địa',
  INTERNATIONAL: 'Quốc tế',
  createFeeConfigError: 'Cài đặt phí xuất vé không thành công',
  createFeeConfigSuccess: 'Cài đặt phí xuất vé thành công',
  domesticFee: 'Phí dịch vụ nội địa',
  internationalFee: 'Phí dịch vụ quốc tế',
  domesticFeeList: 'Danh sách Phí dịch vụ nội địa',
  internationalFeeList: 'Danh sách Phí dịch vụ quốc tế',
  children: 'Trẻ em',
  infant: 'Trẻ sơ sinh',
  adult: 'Người lớn',
  ticketType: 'Loại vé',
  domesticTicket: 'Vé nội địa',
  internationalTicket: 'Vé quốc tế',
  placeholder: {
    feeType: 'Lựa chọn Loại phí xuất vé',
    airline: 'Điền vào Mã hãng hàng không',
    ticketType: 'Lựa chọn loại vé',
    amount: 'Điền vào Số tiền',
    distributor: 'Hệ thống đặt vé',
    profileName: 'Nhập tên cấu hình',
  },
  addMore: 'Thêm',
  applyAll: 'Áp dụng cho tất cả',
  columns: {
    airline: 'HT Đặt vé',
    feeType: 'Loại phí',
    adultAmount: 'Người lớn',
    childAmount: 'Trẻ em',
    infantAmount: 'Em bé',
    updatedAt: 'Thời gian sửa đổi',
    action: 'Thao tác',
    amount: 'Phí',
    ticketType: 'Loại hành trình',
  },
  create: {
    title: 'Tạo mới cấu hình phí dịch vụ khách lẻ',
    buttonTitle: 'Tạo mới cấu hình phí dịch vụ',
  },
  profileName: 'Tên cấu hình',
  setAsDefault: 'Đặt làm mặc định',
  deleteProfile: 'Xoá cấu hình',
  default: 'Mặc định',
  updateFeeConfigSuccess: 'Sửa phí dịch vụ thành công',
  updateFeeConfigError: 'Lỗi sửa phí dịch vụ',
  f1AgencyFee: 'Áp dụng cộng phí xuất vé vào giá NET cho F2',
  f1AgencyFeeConfirm: 'Xác nhận cộng phí xuất vé vào giá NET cho F2?',
  f1AgencyFeeSuccess: 'Áp dụng cộng phí xuất vé thành công.',
  f1AgencyFeeError: 'Lỗi Áp dụng cộng phí xuất vé.',
}
