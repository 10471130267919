export default {
  OW: 'One Way',
  RT: 'Round Trip',
  MC: 'Multi-City',

  Infant: 'Infant',
  Child: 'Child',
  Adult: 'Adult',
  numPassenger: 'Number of passengers',
  adultRange: 'Age 12 and over',
  childRange: 'Age 2 - 11',
  infantRange: 'Below age 2',

  airlineSearchPlaceholder: 'Select Sources',
  carriersSearchPlaceholder: 'Select carriers (1G)',
  classFilterSearchPlaceholder: 'Select class',
  searchFlight: 'Search flight',
  search: 'Search',

  loadingAirport: 'Loading airport...',
  loading: 'Loading...',
  noResultFound: 'No results found for ',
  noOptions: 'Sorry, no matching options.',
  startDate: 'Start date',
  returnDate: 'Return date',

  sortBy: 'Sort by',
  filters: 'Filters',
  maxPrice: 'Max price',
  minPrice: 'Min price',
  clearAllFilters: 'Clear all',
  earliestFlight: 'Earliest',
  cheapestFlight: 'Cheapest',
  fastestFlight: 'Fastest',
  rangePrice: 'Range Price',
  airSource: 'AirSource',
  airline: 'Airline',
  airlineShort: 'Airline',
  airCraft: 'Aircraft',
  numberOfStop: 'No. of Transit',
  departureTime: 'Departure Time',
  Early: 'Early',
  Morning: 'Morning',
  Afternoon: 'Afternoon',
  Evening: 'Evening',
  arrivalTime: 'Arrival Time',
  transitPoints: 'Transit Points',
  flightDuration: 'Flight Duration',
  totalDuration: 'Flight Duration',
  Facilities: 'Facilities',
  'Price/passenger': 'Price/passenger',
  flightPreference: 'Flight preference',
  'Refund/Reschedule': 'Refund & reschedule',
  showPrice: 'Show price',
  viewConfig: {
    CHEAPEST_FARE: 'Cheapest fare',
    EXPENSIVEST_FARE: 'Expensivest fare',
    AIRLINE: 'Airline',
    EARLIEST_DEPARTURE: 'Earliest departure',
    LATEST_DEPARTURE: 'Latest departure',
    EARLIEST_ARRIVAL: 'Earliest arrival',
    LATEST_ARRIVAL: 'Latest arrival',
    SHORTEST_DURATION: 'Shortest duration',
    LONGEST_DURATION: 'Longest duration',
    TOTAL_FARE: 'Total fare',
    NET_FARE: 'NET fare',
    TICKET_FARE: 'Ticket fare',
    HIDE_FARE: 'Hide fare',
    discountedPrice: 'Discounted price',
    showFeeService: 'Show service fee ',
    showAgService: 'Show agency fee',
    showBookingClass: 'Show booking class',
    showAircraft: 'Show aircraft',
  },
  flightDetails: 'Flight Details',
  startFrom: 'Start from',
  endAt: 'Destination',
  midAt: 'Midway',
  changePlane: 'Change plane',
  TakeOffFrom: 'Take-off from: ',
  landingAt: 'Landing at: ',
  flightNumber: 'Flight Number',
  fareType: 'Fare Type',
  Baggage: 'Baggage',
  cabinBaggage: 'Cabin baggage',
  Conditions: 'Conditions',
  'fare&benefit': 'Fare & Benefits',
  noRefund: 'Non-Refundable',
  Refund: 'Refundable',
  refundTitle: 'Refund',
  refundTitleVJ: 'Refund',
  'Refund&Fee': 'Refundable with fee',
  noReschedule: 'Reschedule Not Available',
  Reschedule: 'Reschedule',

  priceDetails: 'Price Details',
  Tax: 'Tax',
  Save: 'Save',
  Total: 'Total',
  tempTotal: 'Total(Temporary)',

  benefit: 'Benefit',
  refundPolicy: 'Refund Policy',
  refundProcess: 'Refund Process',
  refundInfo: 'Refund info',
  Cancel: 'Cancel',
  Next: 'Next',
  Back: 'Back',
  Reservation: 'Reservation',
  Review: 'Review',

  contactInfo: 'Contact information',
  reserCodeSend: 'Reservation code will be sent to the contact information below.',
  agencyInfo: 'Agency information',
  customerInfo: 'Customer information',
  reserCodeSendAG: 'Reservation code will be sent to the agency information below.',
  lastName: 'Last name',
  firstName: 'First name',
  fullName: 'Full name',
  email: 'Email',
  phone: 'Phone number',
  nationality: 'Nationality',
  placeholderInput: 'Please input',
  placeholderSelect: 'Please select',
  Honorific: 'Honorific',
  phoneNumber: 'Phone number',

  passengerInfo: 'Passenger information',
  passengerInfoNote: "Type in Vietnamese without accents, make sure that the passenger's name is exactly as written in the government issued ID/Passport/Driving License.",
  sameInfoContact: 'Passenger information is contact information',
  labelAdult2: 'Full name - Adult 2',

  quickInputPaxInfo: 'Quick input multiple passengers information',
  quickInputPaxInfoNoteQuantity: 'Quick input information for:',
  adultPlural: 'adult | adults',
  childPlural: 'child | children',
  infantdPlural: 'infant | infants',
  quickInputPaxInfoNote: `Guide for quickly input information of multiple passengers:<br>
<br>
Each line is for a passenger's information.<br>
Enter full name right after the adult/child/infant honorific:<br>
  - Adult honorifics: <span class="text-danger font-medium-1">Mr | Mrs | Ms</span><br>
  - Children honorifics: <span class="text-danger font-medium-1">Mstr | Miss</span><br>
  - Infant honorifics (under 2 years old): <span class="text-danger font-medium-1">Mstr | Miss (infants must be entered directly below children)</span><br>
<br>
<div class="text-dark font-small-4 font-weight-bolder mb-50">- Example: </div>
<span class="text-warning font-medium-1 font-weight-bolder ml-md-1 mb-50"> TRAN VAN NAM MR</span><br>
<span class="text-warning font-medium-1 font-weight-bolder ml-md-1"> LE THI THU MRS</span><br>
<em class="mt-50">Enter date of birth according to format: 15/05/1996</em>`,

  combineFullNameHelp: `If a passenger's surname has multiple words, they will be separated by a <b>/</b>, and the system will interpret everything before the first <b>/</b> as the surname and everything after it as the middle name and given name.
    <br>Example:</span> Surname / given name
    <br>Example: Last Name / First Name`,
  extraBaggage: 'Extra checked baggage',
  extraBaggageText:
    'Add luggage to make the trip more convenient. Buy now cheaper at the counter.',

  DiscountCode: 'Discount code',
  selectDiscount: 'Select discount',
  selectDiscountPlaceholder: 'Input discount',
  useCode: 'Use code',
  selectCode_1: 'Or choose a discount code below',
  selectCode_2: 'code',
  selectCode_3: 'No discount codes found.',

  exportReceipt: 'Export receipt',
  exportReceiptText: 'When you need to issue a VAT invoice, please submit your request within 48 hours (and no later than 10 pm on the last day) on the application.',
  requestInvoice: 'Request an invoice',
  termsInvoice: 'Terms of Invoice',

  buyIns: 'Buy insurance',
  buyInsText: 'People',

  paymentMethod: 'Payment methods',
  paymentMethodText:
    'After completing the payment, the e-ticket will be sent immediately via your SMS and Email.',
  issueTicket: 'Issue the ticket',
  Reservations: 'Reservations',
  reservationsIn: 'Reservations in',

  Buy: 'Buy',
  buyText_1:
    'By clicking this button, you acknowledge that you have read and agree to',
  buyText_2: 'Terms and Conditions',
  buyText_3: 'ours.',

  trip: 'Trip(s)',
  totalFare: 'Total price of tickets',
  total: 'Total',
  discount: 'Discount',
  Promotion: 'Promotion',
  fareText: 'Taxes, fees included',

  sourceBy: 'Operated by',
  direct: 'Direct',
  '1_transits': '1 transit(s)',
  '2_transits': '2 transits',

  seatAvailable: 'Seat available',
  createBooking: 'Pay Later',
  Creating: 'Creating',
  addonAncillary: 'Additional Baggage - Insurance',
  detailAddonAncillary: 'Add services to the trip. Cheaper to buy over the counter.',
  noAncillary: 'There are currently no additional services for this flight!',
  flightSegment: 'Flight segment',
  itinerary: 'Itinerary',
  segment: 'Segment',
  addons: 'Add-ons services',
  addonsType: 'Add-ons',
  bag: 'Baggage',
  addBag: 'Extra baggage',
  extra: 'Extra services',
  passenger: 'Passenger',
  servicePlaceholder: 'Select service',
  Amount: 'Amount',
  package: 'package',
  clear: 'Clear',
  addService: 'Add service',
  addAdult: 'Add Adult',
  addChild: 'Add Child',
  addInfant: 'Add Infant',
  dob: 'Birthday (DD-MM-YYYY)',
  Details: 'Details',
  'Additional information': 'Additional information',
  memberFly: 'Membership card code',
  selectAdult: 'Select Adult',
  requiredAdult: 'The infant must be accompanied by an adult',
  maxLengthInfant:
    'The number of INFANT cannot be more than the number of ADULT passengers.',
  expirationDate: 'Expiration date',
  issueDate: 'Issue date',
  gender: 'Gender',
  stopChangePlane: 'Stop to change planes',
  selectSeats: 'Select seats',
  selectSeatsDetail: 'Choose your seat on the flight',
  noSeatService: 'There are currently no seat reservations for this segment!',
  seatNumber: 'Seat number',
  Row: 'Row',
  seatDescription: 'Description',
  Price: 'Price',
  priceDetail: 'Information about fares, fees and accompanying services',
  serviceCharge: 'Service Fee',
  Discount: 'Discount',
  noteDiscount: 'Total amount does not include commission.',
  Service: 'Service',
  Action: 'Action',
  exportIMG: 'Export Image',
  exportPDF: 'Export PDF',
  export: 'Export',
  renameExport: 'Change filename',
  filename: 'Filename',
  filenamePlaceholder: 'Enter filename',
  Nationality: 'Nationality',
  Passport: 'Passport Number',
  CCCD: 'CCCD Number',
  placeIssue: 'Place of issue',
  totalPriceItineraries: 'Total fare',

  cheapestFareNotFound: 'Data not found',

  noAddonsResult: 'No additional services selected.',
  registerMembershipCard: 'Register Membership Card',
  nationalityPlaceholder: 'Select nationality',
  paynow: 'Pay now',
  confirm: 'Confirm',
  confirmPrice: 'Confirm price',
  close: 'Close',
  seatDetails: 'Seat details',
  Seat: 'Seat',
  passengerMore9:
    'The number of adults and children cannot exceed nine (9) passengers in a booking',
  passengerMore:
    'The number of adults and children cannot exceed {limitPax} passengers in a booking',
  directFlightSelect: 'Direct flight',
  Available: 'Available',
  Unavailable: 'Unavailable',
  atStopPoint: 'At this stop, you need to:',
  atStopPoint_1: 'Collect and recheck your baggage',
  modalMemberShipCard: 'Register for Membership Card',
  labelSelectAirlines: 'Select airlines to register membership card',
  copyPrice: 'Copy price',
  exportPrice: 'Export PDF',
  copyImageToClipboard: 'Copy Image',
  splitAmount: 'Split amount/Page',
  nextFlight: 'Next Flight',
  submit: 'Submit',
  promoCode: 'Promo code',
  searching: 'Searching',
  notBookingFound: 'No matching flights',

  Window: 'Window seat',
  AisleSeat: 'Aisle seat',
  ExitRowSeat: 'Seats next to the exit',
  RightSideOfAircraft: 'Right side of aircraft',
  LeftSideOfAircraft: 'Left side of aircraft',
  OverWingSeat: 'Over wing seat',
  CenterSectionSeat: 'Center section seat',
  LimitedRecline: 'Seats with limited reclining ability',
  BulkheadFront: 'Seat with wide legroom',
  seatIsAvailable: 'Seat is available',
  standardSeat: 'Standard seat',
  upfrontSeat: 'Upfront seat',

  FrontOfCabinClassCompartment: 'Front of cabin class/compartment',
  SeatSuitableForAdultWithAnInfant: 'Seat suitable for adult with an infant',
  BufferZoneSeat: 'Buffer zone seat',
  ChargeableSeats: 'Chargeable seats',
  RestrictedSeatGeneral: 'Restricted seat - General',
  SeatWithBassinetFacility: 'Seat with bassinet facility',
  NoFacilitySeatIndifferentSeat: 'No facility seat (indifferent seat)',

  seatType: 'Seat type',
  addAnotherFlight: 'Add another flight',
  notSupportSeat: 'Seat selection on this segment is not supported!',
  deletePassenger: 'Delete passenger',
  confirmDeletePassenger: 'Are you sure to delete the passenger?',
  cannotDeletePassenger: 'Passengers cannot be deleted!',
  leastOneAdult: 'Reservations must have at least one ADULT passenger!',
  toAddNewInf: 'To add a new INFANT, please search for flights again.',
  toAddNewChd: 'To add a new CHILD, please search for flights again.',
  transitFlightInfo:
    'Transit flight information please contact Booker for help.',
  totalPayment: 'Total payment',
  alertNextDay: 'The flight depart the next day',
  alertNextAirport: 'The flight airport changed',

  monthlyCheapestFare: 'Monthly cheapest fare',
  isGroupedItineraryResponse: 'Combinate fare',
  isSplitTripFare: 'Split trip fare',
  classBooking: 'Class booking',
  currency: 'Currency',
  default: 'Default',
  keepClass: 'Keep ticket class',
  searchClass: {
    totalNet: 'Total net',
    totalTax: 'Total tax',
    totalPrice: 'Total price',
    M1B: 'Minimum 1 package of luggage',
    CRF: 'Allowed to refund tickets',
    NRF: 'Do not refund tickets',
  },

  fareRuleTimeStatus: {
    Anytime: 'Anytime',
    'Before Departure': 'Before departure',
    BeforeDeparture: 'Before departure',
    refundable: 'Refundable',
    noRefundable: 'No refundable',
    'Anytime And Before Departure': 'Anytime And Before Departure',
    reschedulable: 'Reschedulable',
    noReschedulable: 'No reschedulable',
    noshow: 'Can No Show',
    notNoshow: 'Cannot No Show',
    'After Departure': 'After departure',
    AfterDeparture: 'After departure',
    'Anytime and Before Departure': 'Anytime and Before Departure',
  },

  quickInputPax: {
    title: 'Quick input pax',
    bookingCodePlaceholder: 'Enter the 6-digit PNR',
  },
  cheapestSearch: 'Monthly Cheapest Search',
  selectDepartDay: 'Select depart date',
  selectReturnDay: 'Select return date',

  source: 'Source',
  airlines: {
    QH: 'Bamboo Airways',
    VN1A: 'Vietnam Airlines',
    VN1A_MT: 'Vietnam Airlines',
    VN: 'Vietnam Airlines',
    VU: 'Vietravel Airlines',
    VJ: 'Vietjet Air',
    '1G': 'Travelport',
    NDC: 'New Distribution Capability',
    GDS: 'Global Distribution System',
    '1S': 'VNA Southern',
    '1S_CTRL': 'VNA Central',
    F1: 'Other',
    AK: 'AirAsia',
    TH: 'Scoot',
    '1A': 'Amadeus',
    '1B': 'Sabre',
    TR: 'TR Scoot',
    JQ: 'Jetstar Airways',
    TRAIN: 'Train',
    TOUR: 'Tour',
  },
  sourceName: {
    QH: 'QH',
    VN1A: 'VN1A',
    VN1A_MT: 'VN1A_MT',
    VN: 'VN',
    VU: 'VU',
    VJ: 'VJ',
    '1G': '1G',
    NDC: 'NDC',
    GDS: 'GDS',
    '1S': '1S',
    '1S_CTRL': '1S_CTRL',
    F1: 'OF',
    AK: 'AK',
    TH: 'TR',
    TR: 'TR',
    JQ: 'JQ',
    '1A': '1A',
    '1B': '1B',
    TRAIN: 'TRAIN',
    TOUR: 'TOUR',
  },
  fareRule: 'Fare rule',
  linkFareRule: 'Link fare rule',
  linkBaggageRule: 'Link baggage rule',

  checkboxReplacePhoneAgencyData: 'Update customer\'s phone on booking.',
  detailReplacePhoneAgencyData: 'Choose here if you want when the flight changes/ there are problems arising, the airline will contact the customer directly on this phone number.',
  checkboxReplaceEmailAgencyData: 'Update customer\'s email on booking.',
  detailReplaceEmailAgencyData: 'Note: Choose here, customers will receive all the information of the booking, including the system price.',
  checkboxSendEmployeeMailAirline: 'Update user information on booking.',
  removeCustomerSelected: 'Unselect customer',
  customer: 'Customer',

  stopPoint: {
    Direct: 'Direct',
    '1 transit': '1 transit',
    '2 transit': '2 transits',
    '3 transit': '3 transits',
    '4 transit': '4 transits',
  },

  infoEmailReplace:
    "If you do not enter an email, the default will be sent to the user's email address (agency).",

  splitItineraries: 'Split itineraries',
  boardingBaggage: 'Carry on baggage',
  checkinBaggage: 'Checked baggage',

  priceReport: {
    airline: 'Airline',
    transitAt: 'Transit at',
    flightNumber: 'Flight Number',
    departTime: 'Departure Time',
    arrivalTime: 'Arrival Time',
    price: 'Price',
    total: 'Total',
    carryBag: 'Carry on baggage',
    checkinBag: 'Checked baggage',
  },
  'confirmChange&continue': 'Confirm change & continue',
  'confirmChange&book': 'Confirm change & book',
  errLengthPax: 'Error length passenger information',
  callBooker1:
    'Please correct passenger information or contact Booker for processing!',
  callBooker2:
    'If you do not agree with the above changes, please correct your passenger information or contact Booker for processing!',
  overLength1:
    'Passenger {lastName} {firstName} has information that is too long.',
  overLength2:
    'Adult passenger {adtLastName} {adtFirstName} and infant {infLastName} {infFirstName} have too long information.',
  overLength3:
    'Passenger information is too long, in this case the infant {infLastName} {infFirstName} will be renamed to {infLastName} {strNameInfant}.',

  // ANCHOR agency balance
  agencyBalance: {
    title: 'Agency balance',
    fetchError: 'Error loading agency balance',
    source: 'Source',
    amount: 'Amount',
    warningBalance: 'Warning balance',
  },
  full: 'Full',
  flightInfo: 'Flight infomation',
  fareNotIncludeTax: 'Ticket prices do not include taxes and fees',
  fareNotIncludeServiceFee: 'Ticket prices do not include service fees',
  netFare: 'Net fare',
  promotion: 'Promotion',
  contactBookerForDiscount:
    'Airline discount: Please see BSP discount table - Or contact booker, sales',
  discountAirline: 'Discount',
  taxes: 'Taxes, fees',
  agencyFee: 'Agency fee',
  selectAirlineCreateMembershipCard:
    'Select the airline to create a membership card',
  creatingMembershipCard: 'Creating Membership Card',
  noItiSelected: 'Please select a flight',
  flight: 'Flights',

  shortenBooking: 'Shorten booking',
  shortenBooking1A: 'Shorten booking 1A',
  shortenBaggages: 'Baggages',
  shortenFareRules: 'Fare rules',

  setServiceFee: 'Edit service fee',
  useProfileServiceFee: 'Use profile',
  applyProfileServiceFeeSuccessful: 'Apply service fee successfully',

  classFilter: {
    ECONOMY: 'Economy',
    PREMIUM_ECONOMY: 'Premium Economy',
    BUSINESS: 'Business',
    FIRST: 'First class',
  },

  fareRules: {
    title: 'Fare rules',
    titleFromAirline: 'Shorten Fare Rules',
    refundableAllow: 'Refundable: Allow',
    refundable: 'Allow refund',
    refundableIdentify: 'Allow refund',
    noRefundable: 'Disallow refund',
    contactBooker: 'Please contact Booker for fees',
    reschedulableAllow: 'Reschedulable: Allow',
    reschedulable: 'Allow reschedule',
    noReschedulable: 'Disallow reschedule',
    allow: 'Allow',
  },
  combinationTab: {
    table: {
      paxType: 'Pax type',
      amount: 'Amount',
      netFare: 'Net',
      taxFare: 'Tax',
      totalFare: 'Total',
    },
  },
  bagNotInclude: 'Not included',
  flightHaveStop: 'The flight has a stop',
  flightHaveNumberStop: 'The flight has {num} stop',
  notSelectedFlights: 'Please select a flight',
  neighboringDays: 'Neighboring days ',
  showCheapestFareSwiper: 'Show cheapest fare',
  hideCheapestFareSwiper: 'Hide cheapest fare',
  showBannerSearch: 'Show banner',
  hideBannerSearch: 'Hide banner',
  terminalNumber: 'Terminal',
  groupBooking: {
    calculate: 'Calculate group price',
    book: 'Create group booking',
    'Group pricing only supports domestic trips!':
      'Group pricing only supports domestic trips!',
    'Please select all itineraries!': 'Please select all itineraries!',
    'Group price calculation, group booking is not supported for VN1A trips!':
      'Group price calculation, group booking is not supported for VN1A trips!',
  },
  'You do not have permission to book airline tickets. Please contact the managing agent to book tickets!':
    'You do not have permission to book {airline} tickets.\nPlease contact the managing agent to book tickets!',
  'VNA on the current itinerary does not support booking tickets with 2 different sources, please choose again!':
    'VNA on the current itinerary does not support booking tickets with 2 different source, please choose again!',
  'Departure time must always be after the end time of the previous journey!':
    'Departure time must always be after the end time of the previous journey!',
  invalidSelectGroupFare: 'You are choosing a {source} round-trip ticket with combined prices. Please select the departure and return trips with {source} to get the exact price!',
  profit: 'Profit',
  addBaggage: 'Add Baggage',
  addSeat: 'Add Seat',
  addOtherServices: 'Add Other Services',
  changePassengerAmount: 'Change passenger amount',
  'Duplicate bookings': 'Duplicate bookings',
  Processing: 'Processing...',
  'Reservation information': 'Reservation information:',
  createBookingSuccessful: 'Create booking success!',
  bookingCode: 'Booking code',
  noInformation: 'No information',
  errLoadingFlight: 'Lỗi tải thông tin chuyến bay!',
  plsCalcFlight: 'Please calculate the price to see the cruise price',
  errCalcFlight: 'Error calculating itinerary price',
  'Pricing information': 'Pricing information',
  'Total ticket price': 'Total ticket price',
  'Please calculate the price to get the exact price':
    'Please calculate the price to get the exact price!',
  showTransitFlight: 'Show transit flight',
  'Departure time': 'Departure time',
  combinatePrice: 'Combinate price',
  'For Round-trip flights, please select Calculate combined price to get the exact price':
    'For Round-trip flights, please select "Calculate combined price" to get the exact price!',
  'To calculate the combined price, you need to choose 2 or more itineraries':
    'To calculate the combined price, you need to choose 2 or more itineraries!',
  'Loading more flights': 'Loading more flights...',
  loadMore: 'Load more',
  'The number of passengers exceeds the number of seats selected, please check again': 'The number of passengers exceeds the number of seats selected, please check again.',
  'You have entered the following names in excess': 'You have entered the following names in excess',
  checkVisa: 'Check VISA for transit flights and immigration',
  noteCalendar: 'Note: Departure time is in local time.',
  historySearchFlight: 'History search',
}
