export default {
  form: {
    title: 'Bank Info',
    id: 'ID',
    name: 'Name',
    code: 'Code',
    shortName: 'Short Name',
    logo: 'Logo',
    bin: 'BIN',
    swiftCode: 'Swift Code',
    support: 'Support',
    gateway: 'Gateway',
    isAuto: 'Auto',
    paymentTxsFee: 'Transaction fee',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    ph: {
      id: 'Enter ID',
      name: 'Enter name',
      code: 'Enter code',
      shortName: 'Enter short name',
      logo: 'Enter logo',
      bin: 'Enter BIN',
      swiftCode: 'Enter Swift code',
      support: 'Enter support',
      gateway: 'Enter gateway',
      isAuto: 'Enter auto',
      paymentTxsFee: 'Enter transaction fee',
      createdAt: 'Enter created date',
      updatedAt: 'Enter updated date',
    },
  },
  columns: {
    stt: 'STT',
    name: 'Name',
    code: 'Code',
    shortName: 'Short Name',
    logo: 'Logo',
    bin: 'BIN',
    swiftCode: 'Swift Code',
    support: 'Support',
    gateway: 'Gateway',
    isAuto: 'Auto',
    paymentTxsFee: 'Transaction fee',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    actions: 'Actions',
  },
  btn: {
    edit: 'Edit',
  },
  updateSuccess: 'Update success',
}
